<template>
  <div>
    <div class="relat">
      <div class="editor">
        <div class="label Ptp35">商品描述</div>
        <div class="editorContainer Ptp20 Pbm80">
          <div class="home">
            <div id="demo1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
import { uploadFile } from "@/api/product";
export default {
  data() {
    return {
      editor: null,
      editorData: "",
      dialogImageUrl: "",
      dialogVisible: false,
      token: {},
    };
  },
  mounted() {
    const editor = new wangEditor(`#demo1`);

    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };
    editor.config.zIndex = 0;
    editor.config.uploadImgMaxLength = 10;
    editor.config.customUploadImg = (files, insert) => {
      /* files 是 input 中选中的文件列表 */
      let formData = new FormData();
      for (let x = 0; x < files.length; x++) {
        formData.append("file", files[x]);
      }
      formData.append("moduleType", "06");
      uploadFile(formData).then((res) => {
        if (res.success) {
          for (let x = 0; x < res.data.length; x++) {
            insert(res.data[x].filePath);
          }
        }
      });
    };
    editor.config.uploadImgHooks = {
      // 图片上传并返回结果，但图片插入错误时触发
      fail: function (xhr, editor, result) {
        console.log(result);
      },
      success: function (xhr, editor, result) {
        // 图片上传并返回结果，图片插入成功之后触发
        console.log(result, "success");
      },
    };
    // 创建编辑器
    editor.create();

    this.editor = editor;
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getEditorData() {
      // 通过代码获取编辑器内容
      let data = this.editor.txt.html();
      return data;
    },
    setEditorData(data) {
      this.editor.txt.html(data);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 950px;
  margin: auto;
  .demo1 {
    position: relative;
    z-index: 0;
  }
}
.el-button {
  padding-left: 61px;
  padding-right: 61px;
}
</style>